import { Link } from "@nextui-org/react";
import { Head } from "./head";
import React, { useState } from 'react'; // Import React and useState
import { Navbar } from "../components/navbar";
import Settings from "../components/views/settings/settings";

export default function DefaultLayout({
  children,
}: {
  children: React.ReactNode;
}) {

  // Lifted state for showing Settings
  const [showSettings, setShowSettings] = useState(false);

  // Function to toggle Settings display
  const toggleSettings = () => {
    setShowSettings((prev) => !prev);
  };

  return (
    <div className="flex flex-col h-screen">
      <Head />
      <Navbar onSettingsClick={toggleSettings}/> {/* Pass the toggle function */}
      <main className="container mx-auto max-w-7xl px-6 flex-grow">
        {children}
        {/* Conditionally render Settings component */}
        {showSettings && (
          <div className="z-50 fixed inset-0 flex items-center justify-center z-1000">
            <Settings onConfirm={() => setShowSettings(false)} /> {/* Pass close handler */}
          </div>
        )}
      </main>
      <footer className="w-full flex items-center justify-center py-3 fixed bottom-0">
        {/* <Link
          isExternal
          className="flex items-center gap-1 text-current"
          href="https://tiplink.io/blinks/donate?dest=D2yGetspd22V3UFHTudRN1s7bU6DF7JNaLBCn2NQ2KHd"
          title="Buy GeekLad a Coffee or a Lambo"
        >
          <span className="text-default-600">Made with ❤️ by GeekLad&nbsp;</span>
          <p className="text-primary">Buy him a coffee or a lambo</p>
        </Link> */}
      </footer>
    </div>
  );
}
