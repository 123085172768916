import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Navbar as NextUINavbar,
  NavbarContent,
  NavbarBrand,
  NavbarItem,
} from "@nextui-org/react";
import Link from "next/link";
import dynamic from 'next/dynamic';

import { ThemeSwitch } from "./theme-switch";
import { Logo } from "./icons";
import React, { useState, useEffect } from 'react'; // Import React

import { useAutoConnect } from '../contexts/AutoConnectProvider';
import NetworkSwitcher from './networkSwitcher';

import Settings from "./views/settings/settings";

// Wallet
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import useUserSOLBalanceStore from "../stores/useUserSOLBalanceStore";

const WalletMultiButtonDynamic = dynamic(
  async () => (await import('@solana/wallet-adapter-react-ui')).WalletMultiButton,
  { ssr: false }
);

interface NavbarProps {
  onSettingsClick: () => void;
}

export const Navbar: React.FC<NavbarProps> = ({ onSettingsClick }) => {
  const wallet = useWallet();
  const { autoConnect, setAutoConnect } = useAutoConnect();
  const { connection } = useConnection();
  const balance = useUserSOLBalanceStore((s) => s.balance)
  const { getUserSOLBalance } = useUserSOLBalanceStore()

  useEffect(() => {
      if (wallet.publicKey) {
      // console.log(wallet.publicKey.toBase58())
      getUserSOLBalance(wallet.publicKey, connection)
      }
  }, [wallet.publicKey, connection, getUserSOLBalance])

  // const [showSettings, setShowSettings] = useState(false);

  // const handleSettingsConfirm = () => {
  //   setShowSettings(false);  
  // }  

  // useEffect(() => {
  //   const handleKey = (event: KeyboardEvent) => {
  //       if (event.key === 's') handleShowSettings();
  //   };

  //   window.addEventListener('keydown', handleKey);
  //   return () => {
  //       window.removeEventListener('keydown', handleKey);
  //   };

  // }, [showSettings]);

  return (
    // <NextUINavbar maxWidth="full" position="sticky" className="bg-[#1B1D28] z-1000">
    <NextUINavbar maxWidth="full" position="sticky" className="bg-[#1b1d287a] z-50 border-b-2 border-[#86EFAC] z-100">
      <NavbarContent className="basis-1/5 sm:basis-full" justify="start">
        <NavbarBrand className="gap-3 max-w-fit">
          <Link href="/">
            <Logo />
          </Link>
          <Link href="/">
            <p className="font-bold text-inherit">
            Degens Arena
            </p>
          </Link>
        </NavbarBrand>
      </NavbarContent>

      <NavbarContent className="basis-1/5 sm:basis-full " justify="end">
        <NavbarItem className="flex gap-4">

          {/* { wallet.connected && (
            <div className="hidden md:flex md:flex-col justify-center">
              <div className="flex flex-row navbar-balance">
                <div className='font-medium'>
                    {(balance || 0).toLocaleString()}
                </div>
                <div className='text-sm ml-1'>
                    SOL
                </div>
              </div>
            </div>    
          )} */}
          
          {/* <WalletMultiButtonDynamic className="btn-ghost btn-sm relative flex text-lg " /> */}
          
          <div className="flex flex-col justify-center">
            <div className="settings-icon">
              <button
                onClick={onSettingsClick}
                className=""
              >
                <FontAwesomeIcon icon={faCog} size="lg" className=''/>
              </button>
            </div>
          </div>
          
        </NavbarItem>
      </NavbarContent>

      {/* <NavbarContent className="sm:hidden basis-1 pl-4" justify="end">
        <ThemeSwitch />
      </NavbarContent> */}

    </NextUINavbar>
  );
};

// Fix export default error
export default Navbar;