// pages/stores/useUserSOLBalanceStore.ts
import create from 'zustand';

import { Connection, PublicKey, LAMPORTS_PER_SOL } from '@solana/web3.js';

import { connection, rpc, wallet, global, feeRecipient, PUMP_PROGRAM, payer, eventAuthority, trader } from "../../src/config";

const rpcConnection = connection;
interface UserSOLBalanceStore {
  balance: number;
  getUserSOLBalance: (publicKey: PublicKey, connection: Connection) => void;
}

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const useUserSOLBalanceStore = create<UserSOLBalanceStore>((set) => ({
  balance: 0,
  getUserSOLBalance: async (publicKey) => {
    let balance = 0;
    try {
      await sleep(200)
      balance = await connection.getBalance(publicKey, 'confirmed');
      balance = balance / LAMPORTS_PER_SOL; // Convert to SOL
    } catch (e) {
      console.log(`Error getting balance: `, e);
    }

    // Use functional update to ensure you're working with the latest state
    set((state) => {
      // console.log(`Balance updated: `, balance);
      return { ...state, balance }; // Return a new state object
    });
  },
}));

export default useUserSOLBalanceStore;
