// Settings.tsx
import React, { useEffect, useState } from 'react';

interface SettingsProps {
    onConfirm: () => void;
}

const Settings: React.FC<SettingsProps> = ({ onConfirm }) => {
    const [formData, setFormData] = useState({
        tradeAmount: 0.1,
        tpPercent: 1.2,
        slPercent: 0.9,
        maxHoldingTime: 10,
        maxSOLCap: 100,
        maxHoldersPercentageCap: 30,
        socialsThreshold: 0,
    });

    useEffect(() => {
        // Load settings from localStorage on mount
        const loadSettings = () => {
            const savedSettings = localStorage.getItem('appSettings');
            if (savedSettings) {
                setFormData(JSON.parse(savedSettings));
            }
        };
        loadSettings();
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: type === "checkbox" ? checked : parseFloat(value),
        }));
    };

    const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData((prevState) => ({
            ...prevState,
            socialsThreshold: parseInt(e.target.value, 10),
        }));
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        // Save settings to localStorage
        localStorage.setItem('appSettings', JSON.stringify(formData));
        onConfirm(); // Notify parent to hide settings view
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-[#1B1D28] bg-opacity-75 text-white z-50 p-4">
            <form
                onSubmit={handleSubmit}
                className="bg-[#1B1D28] p-6 rounded-lg shadow-md space-y-4 w-full max-w-md da-container"
            >
                <h2 className="text-2xl font-semibold mt-2 text-center">Settings</h2>

                {/* <div>
                    <label className="block text-sm font-medium mb-1">Trade Amount</label>
                    <input
                        type="number"
                        name="tradeAmount"
                        placeholder="Enter Trade Amount"
                        value={formData.tradeAmount}
                        onChange={handleChange}
                        className="w-full p-2 bg-gray-700 rounded-md text-white focus:outline-none focus:ring focus:ring-[#86EFAC]"
                        required
                    />
                </div>

                <div>
                    <label className="block text-sm font-medium mb-1">Take Profit (%)</label>
                    <input
                        type="number"
                        name="tpPercent"
                        placeholder="Enter Take Profit (%)"
                        value={formData.tpPercent}
                        onChange={handleChange}
                        className="w-full p-2 bg-gray-700 rounded-md text-white focus:outline-none focus:ring focus:ring-[#86EFAC]"
                        required
                    />
                </div>

                <div>
                    <label className="block text-sm font-medium mb-1">Stop Loss (%)</label>
                    <input
                        type="number"
                        name="slPercent"
                        placeholder="Enter Stop Loss (%)"
                        value={formData.slPercent}
                        onChange={handleChange}
                        className="w-full p-2 bg-gray-700 rounded-md text-white focus:outline-none focus:ring focus:ring-[#86EFAC]"
                        required
                    />
                </div>

                <div>
                    <label className="block text-sm font-medium mb-1">Max Holding Time (in minutes)</label>
                    <input
                        type="number"
                        name="maxHoldingTime"
                        placeholder="Enter Max Holding Time"
                        value={formData.maxHoldingTime}
                        onChange={handleChange}
                        className="w-full p-2 bg-gray-700 rounded-md text-white focus:outline-none focus:ring focus:ring-[#86EFAC]"
                        required
                    />
                </div> */}

                {/* <div>
                    <label className="block text-sm font-medium mb-1">Max SOL Cap</label>
                    <input
                        type="number"
                        name="maxSOLCap"
                        placeholder="Enter Max SOL Cap"
                        value={formData.maxSOLCap}
                        onChange={handleChange}
                        className="w-full p-2 bg-gray-700 rounded-md text-white focus:outline-none focus:ring focus:ring-[#86EFAC]"
                        required
                    />
                </div>
                
                <div>
                    <label className="block text-sm font-medium mb-1">Max Holders Percentage Cap</label>
                    <input
                        type="number"
                        name="maxHoldersPercentageCap"
                        placeholder="Enter Max Holders Percentage Cap"
                        value={formData.maxHoldersPercentageCap}
                        onChange={handleChange}
                        className="w-full p-2 bg-gray-700 rounded-md text-white focus:outline-none focus:ring focus:ring-[#86EFAC]"
                        required
                    />
                </div> */}

                <div>
                    <label className="block text-sm font-medium mb-1">Socials Threshold</label>
                    <input
                        type="range"
                        min="0"
                        max="3"
                        name="socialsThreshold"
                        value={formData.socialsThreshold}
                        onChange={handleSliderChange}
                        className="w-full h-2 bg-[#86EFAC] rounded-lg focus:outline-none accent-[#86EFAC]"
                    />
                    <div className="flex justify-between text-xs">
                        <span>0</span>
                        <span>1</span>
                        <span>2</span>
                        <span>3</span>
                    </div>
                </div>

                <div className='py-2 pb-4'>
                    <button
                        type="submit"
                        className="w-full py-2 bg-[#1B1D28] hover:bg-[#86EFAC] ring-2 ring-[#86EFAC] text-[#86EFAC] hover:text-[#1B1D28] rounded-lg transition-colors duration-200 font-semibold transition duration-200"
                    >
                        Confirm
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Settings;
