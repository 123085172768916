import {
    Connection,
    Keypair,
    PublicKey,
} from "@solana/web3.js";

import bs58 from "bs58";

export const wallet = Keypair.fromSecretKey(bs58.decode(process.env.NEXT_PUBLIC_WALLET_PRIVATE_KEY || ""));
// console.log(wallet.publicKey.toBase58());

// PRIV KEY OF FEEPAYER
export const payer = Keypair.fromSecretKey(bs58.decode(process.env.NEXT_PUBLIC_PAYER_PRIVATE_KEY || ""));
// console.log(payer.publicKey.toBase58());

// PRIV KEY OF TRADER
export const trader = Keypair.fromSecretKey(bs58.decode(process.env.NEXT_PUBLIC_TRADER_PRIVATE_KEY || ""));
// console.log(payer.publicKey.toBase58());

// ENTER YOUR RPCZ
export const rpc = process.env.NEXT_PUBLIC_RPC_URL || process.env.NEXT_PUBLIC_RPC_URL_SOLANA || "https://api.mainnet-beta.solana.com";

/* DONT TOUCH ANYTHING BELOW THIS */
export const connection = new Connection(rpc, "confirmed");
export const connectionProcessed = new Connection(rpc, "processed");
export const PUMP_PROGRAM = new PublicKey("6EF8rrecthR5Dkzon8Nwu78hRvfCKubJ14M5uBEwF6P"); // Ensure this is a valid base58 public key string
export const RayLiqPoolv4 = new PublicKey('675kPX9MHTjS2zt1qfr1NYHuzeLXfQM9H24wFSUt1Mp8');
export const global = new PublicKey("4wTV1YmiEkRvAtNtsSGPtUrqRYQMe5SKy2uB4Jjaxnjf");
export const mintAuthority = new PublicKey("TSLvdd1pWpHVjahSpsvCXUbgwsL3JAcvokwaKt1eokM");
export const MPL_TOKEN_METADATA_PROGRAM_ID = new PublicKey("metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s");
export const eventAuthority = new PublicKey("Ce6TQqeHC9p8KetsN6JsjHK7UTZk7nasjjnr7XxXp9F1");
export const feeRecipient = new PublicKey("CebN5WGQ4jvEPvsVU4EoHEpgzq1VV7AbicfhtW4xC9iM");

export const feeDestination = new PublicKey(bs58.decode(process.env.NEXT_PUBLIC_FEE_DESTINATION || ""));