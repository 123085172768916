// Define an interface for the site configuration
interface SiteConfigType {
  name: string;
  description: string;
}

// Use the interface to define the siteConfig
export const siteConfig: SiteConfigType = {
  name: "Degens Arena | PVP Client for Pump Fun and claim tool for Solana close token accounts and redeem SOL",
  description: "Degens Arena | PVP Client for Pump Fun and claim tool for Solana close token accounts and redeem SOL",
};

const SiteConfig = () => {};

export default SiteConfig;
